import { InclusionType_PEnum, TranslationFieldType_PEnum } from '../../../configs/pseudoEnums'
import { TranslatableText } from '../../../utility/TranslatableText'

const initialState = { 
    id: 0,
    profileId: '',
    rateConditions: [
        { type: 'Inclusion', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text), isChanged: false }, 
        { type: 'PaymentCancellation', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text), isChanged: false }, 
        { type: 'ChildPolicy', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text), isChanged: false }, 
        { type: 'TermsConditions', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text), isChanged: false }     //TODO: this array should really be empty until we add conditions, but it affects too much functionality in how the TranslatableTextEditor works with rates overlay.
    ],
    ratePricings: [],
    rateSeasons: [],
    currencyCode: 'ZAR',
    existsInDb: false,
    ratePricingConfigurations: [],
    hideNettRate: true,
    nettRate: null,
    rateExtraFeeItems: [],
    onlineRatesUrls: {},     //Dictionary: {profileItemType: Url}. This is to handle venues which have a different Url for each profileItem type.
    conditionsDraftJS: {}
}

export const getNewRatePricingConfig = (configType, profileItemId, parentProfileItemId, isDummyElement = false, id = 0, isPricingFrom = false) => {
    return {
        id,
        profileRateId: 0,
        profileItemId,
        discountedValue: 0,
        isPricingFrom: isPricingFrom, 
        configType: configType ? configType : '',       //TODO: make enum
        isPP: false,            //isPP/isPPS: it can be neither, one or the other, but not both.
        isPPS: false,
        fromValue: '',          //Can include a '+' sign so varchar
        toValue: '',
        existsInDb: false,
        isMarkedForDeletion: false,
        isSelected: false,
        isDummyElement,         //To avoid the controlled/uncontrolled error.
        parentProfileItemId,
        gridSequence: 0,
        title: ''
    }
}

export const getNewRateExtraFeeItem = (id) => {
    return {
        id,
        itemName: '',
        price: null,
        inclusionType: InclusionType_PEnum.none
    }
}

export const getNewRatePricing = (rateSeasonId, ratePricingConfigurationId) => {
    return {
        rateSeasonId, 
        ratePricingConfigurationId,
        id: 0,      //This can just remain 0 on the UI if !existsInDb because it is not used (we use rateSeasonId and rateConfigId to identify).
        existsInDb: false,
        isMarkedForDeletion: false
    }
}

const ratesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PROFILE_ID':
            return {...state, profileId: action.data }
        case 'SET_RATES_DATA':
            const { rateConditions, ...other } = action.data 
            if (rateConditions?.length > 0) {
                const currentConditions = _.cloneDeep(state.rateConditions)
                rateConditions.forEach(condition => {
                    const conditionInCurrentList = currentConditions.find(c => c.type === condition.type)
                    conditionInCurrentList.text = condition.text
                    conditionInCurrentList.id = condition.id
                    conditionInCurrentList.existsInDb = condition.existsInDb
                })
                currentConditions.forEach(condition => { condition.isMarkedForDeletion = false })
                return {...state, ...action.data, rateConditions: currentConditions, conditionsDraftJS: {} }
            }
            return {...state, ...other, conditionsDraftJS: {}}
        case 'SET_RATE_PRICINGS':
            return {...state, ratePricings: action.data }
        case 'SET_RATE_CONDITIONS':
            const conditionsPayload = action.data
            if (conditionsPayload?.length > 0) {
                const stateConditionsCopy = _.cloneDeep(state.rateConditions)
                conditionsPayload.forEach(condition => {
                    const conditionInCurrentList = stateConditionsCopy.find(c => c.type === condition.type)
                    conditionInCurrentList.text = condition.text
                    conditionInCurrentList.isChanged = condition.isChanged
                    conditionInCurrentList.isMarkedForDeletion = condition.isMarkedForDeletion
                    conditionInCurrentList.existsInDb = condition.existsInDb
                    conditionInCurrentList.id = condition.id
                })
                return {...state, rateConditions: stateConditionsCopy }
            }
            return state
        case 'SET_PROFILE_RATE_SEASONS':
            return {...state, rateSeasons: action.data }
        case 'SET_SEASON_RANGES':
            return {...state, season:{...state.season, dateRanges: action.data }}
        case 'DELETE_SEASON':
            const seasonsClone = _.cloneDeep(state.rateSeasons)
            const ratePricingsClone = _.cloneDeep(state.ratePricings)
            return {...state, rateSeasons: seasonsClone.filter(season => season.id !== action.data), rates: ratePricingsClone.filter(rate => rate.RateSeasonId !== action.data)}
        case 'SET_RATES_PRICING_OPTIONS':
            return {...state, pricingOptions: action.data }
        case 'SET_HIDE_NET_RATE':
            return {...state, hideNettRate: action.data }
        case 'SET_NETT_RATE':
            return {...state, nettRate: action.data }
        case 'SET_EXTRA_FEES':
            return {...state, rateExtraFeeItems: action.data }
        case 'SET_CURRENCY_CODE':
            return {...state, currencyCode: action.data }
        case 'SET_RATES_URL':
            return {...state, onlineRatesUrls: action.data }
        case 'SET_CONDITIONS_DRAFTJS':
            const currentConditionsDraftJS = _.cloneDeep(state.conditionsDraftJS)
            return {...state, conditionsDraftJS: {...currentConditionsDraftJS, ...action.data}}
        case 'RESET_RATES':
            const defaultConditions = [
                { type: 'Inclusion', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text) }, 
                { type: 'PaymentCancellation', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text) }, 
                { type: 'ChildPolicy', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text) }, 
                { type: 'TermsConditions', text: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Text) }
            ]
            return {...initialState, profileId: action.data, rateConditions: defaultConditions}
        case 'SET_RATE_PRICING_CONFIGURATIONS':
            return {...state, ratePricingConfigurations: action.data }
        default:
            return state
    }
}

export default ratesReducer