import { TranslationFieldType_PEnum } from '../../../configs/pseudoEnums'
import { sortObjectArray, updateOrAddItem } from '../../../utility/Utils'

// ** Initial State
const initialState = {
  directions: [],
  isValidated: false,
  isLoading: true,
  isSaving: false,
  isSubmitting: false,
  currentEditorLanguageCodes: {0: 'en'}   
}

export const getDefaultDirection = (profileId) => {
  return {
    id: '',
    name: TranslatableText.getNew(true, '', TranslationFieldType_PEnum.Name),
    originId: '', 
    destinationId: '', 
    originPin: {},
    destinationPin: {}, 
    originName: '',  
    destinationName: '',  
    wayPoints: '', 
    instructions: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.Direction),
    isChanged: false,
    profileId,
    sequence: 0,
    isDirectionTo: true,
    count: 1,
    size: 0,
    existsInDb: false
  }
}

const directionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DIRECTION':
      return { ...state, ...state.directions.find(x => x.id === action.data)}
    case 'GET_DIRECTIONS':
      return { ...state, directions: action.data }
    case 'SET_IS_VALIDATED':
      return {...state, profile: {...state.directions, isValidated: action.data}}
    case 'SET_IS_LOADING':
      return {...state, profile: {...state.directions, isLoading: action.data}}
    case 'SET_IS_SUBMITTING':
      return {...state, profile: {...state.directions, isSubmitting: action.data}}
    case 'DELETE_DIRECTION':
      state.directions.splice(state.directions.findIndex(x => x.id === action.data), 1)
      return {...state, directions: _.cloneDeep(state.directions) }
    case 'SET_DIRECTION':
      return {...state, 
        directions: sortObjectArray(updateOrAddItem(state.directions, action.data, 'id'), 'sequence')
      }
    case 'SET_DIRECTIONS':
      return { ...state, directions: action.data, isLoading: false, isSaving: false, isSubmitting: false
      }
    case 'RESET_DIRECTIONS':
      return {...state, 
        directions: initialState.directions,
        isValidated: initialState.isValidated,
        isLoading: initialState.isLoading,
        isSaving: initialState.isSaving,
        isSubmitting: initialState.isSubmitting
      }
    default:
      return state
  }
}

export default directionReducer
