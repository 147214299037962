import _ from 'lodash'
import { DocumentTypes_DB_PEnum, ProfileItemVisibility_DB_PEnum, TranslationFieldType_PEnum } from '../../../configs/pseudoEnums'
import { TranslatableText } from '../../../utility/TranslatableText'
import { removeObjectArrayElements, sortObjectArray } from '../../../utility/Utils'

// ** Initial State
const initialState = {
    mediaItems: [],
    mediaItemSummary: [],    
    currentIndex: 0,
    isMediaLoaded: false,
    destinationThumbnails:[],
    showEditImageOverlaySaveToast: false,    //If the overlay is opened from the profileItem overlay it should show a save toast.
    fileUploadProgress: {}
}

export const getDefaultMediaItem = (mediaType = '') => {
  return {
    id: 0,
    blobId: Date.now(), //newUuid(),        //We need a GUID because this needs to be unique, because we store the blob directly from the UI (before we have generated a mediaItem.id [int] on the API side).
    name: TranslatableText.getNew(true, '', TranslationFieldType_PEnum.Name),
    profileId: '',
    parentId: '',
    parentEntityType: '',
    mediaType: mediaType,
    documentType: DocumentTypes_DB_PEnum.none,
    blobRelativePath: '',     //Format: profileId/fileName (where fileName is mediaItem.id_originalFileName)
    localUrl: '',
    blobUrl: '',
    embedUrl: '',           //For embedded videos.
    azureContainerName: '',
    credit: '',
    fileName: '',
    fileType: '',
    hasFileSizeWarning: false,
    fileSize: 0,
    width: 0,
    height: 0,
    originalFileName: '',
    sequence: 0,
    hasAcceptedUsagePolicy: true,
    visibility: ProfileItemVisibility_DB_PEnum.publicAndTrade,
    uploadErrorMessage: '',
    description: TranslatableText.getNew(true, '', TranslationFieldType_PEnum.Description),
    lastModified: '',
    isChanged: true,      //True if an item has been added, edited or deleted. If MediaItem (not blob) has changed (sequence, credit, name etc.) and DB needs to be updated. Use blobUrl to detect if blob exists in db.
    isMarkedForDeletion: false,        //True if an item is marked for deletion
    existsInDb: false,
    isRoomPlanImage: false,
    autoTags: [],
    manualTags: "",
    thumbnailUrl: '',
    thumbnailBlobRelativePath: '',
    isUploadErrored: false
  }
}

const getUpdatedListAfterDb = (oldList, listChanges) => {
  //For each item in listChanges: 
  //if it is a delete, then delete the item from old list. 
  //If it is a change, update the item in old list.
  //If it is unchanged, leave the old item as is.

  const newList = []
  oldList?.forEach(item => {
    const changeItem = listChanges.find(x => x.id === item.id)
    if (changeItem) {
      if (!changeItem.isMarkedForDeletion) {
        newList.push({...changeItem, existsInDb: true, isChanged: false})
      }
    } else {
      newList.push(item)
    }
  })
  listChanges?.forEach(item => {
    if (!oldList?.find(x => x.id === item.id)) {
      newList.push({...item, existsInDb: true})
    }
  })
  return newList
}

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEDIAITEMS':
      return { ...state, mediaItems: action.data, isMediaLoaded: true }
    
    case 'ADD_MEDIAITEMS': {
      let cloneAddMediaItems = _.cloneDeep(state.mediaItems)
      cloneAddMediaItems = cloneAddMediaItems.filter(x => !x.blobId)

      // filter out media that was not added but edited at the same time.
      const existingMediaItems = action.data.filter(x => cloneAddMediaItems.some(y => y.id === x.id))
      
      // get the added media that was part of the added/updated media
      const newMediaItems = action.data.filter(x => !existingMediaItems.some(y => y.id === x.id))

      existingMediaItems.forEach(media => {
        const updateMediaIndex = cloneAddMediaItems.findIndex(x => x.id === media.id)
        cloneAddMediaItems[updateMediaIndex] = media
      })

      if (newMediaItems) {
        cloneAddMediaItems = [...cloneAddMediaItems, ...newMediaItems]
      }

      cloneAddMediaItems.sort((a, b) => a.sequence - b.sequence)

      
      return { ...state, mediaItems: cloneAddMediaItems }
    }  

    case 'UPDATE_MEDIAITEMS': {
      let cloneUpdateMediaItems = _.cloneDeep(state.mediaItems)
      
      if (action.data.deletedItem.length > 0) {
        cloneUpdateMediaItems = cloneUpdateMediaItems.filter(item => !action.data.deletedItem.some(deletedItem => deletedItem.id === item.id))
      }

      action.data?.mediaItems?.forEach(media => {
        const updateMediaIndex = cloneUpdateMediaItems.findIndex(x => x.id === media.id)
        if (updateMediaIndex !== -1) {
          cloneUpdateMediaItems[updateMediaIndex] = media
        } else {
          cloneUpdateMediaItems.push(media)
        }
      })

      cloneUpdateMediaItems.sort((a, b) => a.sequence - b.sequence)
      
      return { ...state, mediaItems: cloneUpdateMediaItems }
    }
    case 'SET_MEDIAITEMS_SUMMARY':
      return { ...state, mediaItemsSummary: action.data }
    case 'DELETE_MEDIAITEM':
      state.mediaItems.splice(state.mediaItems.findIndex(x => x.id === action.data), 1)
      return {...state, mediaItems: state.mediaItems }
    case 'DELETE_MEDIAITEMS':
      const remaining = removeObjectArrayElements(state.mediaItems, 'id', action.data)
      return {...state, mediaItems: remaining }
    case 'SET_SELECTED_MEDIAITEMS_AFTER_DB_UPDATE':
      let newMediaItems = getUpdatedListAfterDb(state.mediaItems, action.data)
      newMediaItems = sortObjectArray(_.cloneDeep(newMediaItems), 'sequence', 'mediaType')
      return { ...state, mediaItems: newMediaItems }
    case 'RESET_MEDIAITEMS':
      return {...state, mediaItems: initialState.mediaItems, mediaItemSummary: initialState.mediaItemSummary}
    case 'SET_CURRENT_INDEX':
      return {...state, currentIndex: action.data}
    case 'SET_ISMEDIALOADED':
      return {...state, isMediaLoaded: action.data}
    case 'SET_DESTINATION_THUMBNAILS':
      return {...state, destinationThumbnails: action.data }
    case 'SET_SHOW_EDIT_IMAGE_OVERLAY_SAVE_TOAST':
      return {...state, showEditImageOverlaySaveToast: action.data }
    case 'SET_MEDIA_UPLOAD_PERCENT':      
      return {...state, fileUploadProgress: {...state.fileUploadProgress, [action.data.mediaId]: action.data.filePercent} }
    case 'SET_PROFILE_ITEM_MEDIA':
      const mediaItemsCopy = _.cloneDeep(state.mediaItems)
      const newlyUploadedMedia = mediaItemsCopy.filter(x => action.data.blobIds.includes(x.blobId))
      newlyUploadedMedia.forEach(mediaItem => {
        const index = mediaItemsCopy.indexOf(mediaItem)
        mediaItemsCopy.splice(index, 1)
      })
      const newStateItems = mediaItemsCopy.concat(action.data.media)
      return {...state, mediaItems: newStateItems}
    default:
      return state
  }
}

export default mediaReducer
