// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import profileReducer from './profile'
import profileItemReducer from './profileItem'
import mediaReducer from './media'
import directionReducer from './directions'
import toasterReducer from './toaster'
import ratesReducer from './rates'
import scheduledDepartures from './scheduledDepartures'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  profileReducer,
  profileItemReducer,
  mediaReducer,
  directionReducer,
  toasterReducer,
  ratesReducer,
  scheduledDepartures
})

export default rootReducer
