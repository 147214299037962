const initialState = {
  scheduledDepartures: null,
  linkedDepartures: null,
  scheduledDeparturesLoading: false
}

const scheduledDepartures = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SCHEDULED_DEPARTURE': {
      const scheduledDepartures = action.data
      return ({ ...state, scheduledDepartures })
    }

    case 'UPDATE_LINKED_SCHEDULED_DEPARTURE': {
      const linkedDepartures = action.data.identifiers
      return ({ ...state, linkedDepartures })
    }

    case 'SCHEDULED_DEPARTURE_IS_LOADING': {
      return ({ ...state, scheduledDeparturesLoading: action.data })
    }
    default:
      return state
  }
}

export default scheduledDepartures