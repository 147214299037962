
// ** Initial State
const initialState = {
    show: false,
    title: '',
    message: '',
    showSuccessTick: false,
    showError: false,
    autoHide: true,
    autoHideDelay: 6000,
    customCss: {},
    customHeader: null,
    timeoutId: 0    //The machine translations toast needs to be globally accessible. If you re-request MT on another page it should cancel the first checkExistsInCache request.
}

const toasterReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_TOAST':
        return {
            ...state, 
            show: action.data.show, 
            title: action.data.title, 
            message: action.data.message, 
            autoHide: typeof action.data.autoHide !== 'undefined' ? action.data.autoHide : true,
            showSuccessTick: typeof action.data.showSuccessTick !== 'undefined' ? action.data.showSuccessTick : false,
            autoHideDelay: typeof action.data.autoHideDelay !== 'undefined' ? action.data.autoHideDelay : state.autoHideDelay,
            showError: typeof action.data.showError !== 'undefined' ? action.data.showError : false,
            customCss: typeof action.data.customCss !== 'undefined' ? action.data.customCss : state.customCss,
            customHeader: typeof action.data.customHeader !== 'undefined' ? action.data.customHeader : null
        }
      case 'CLOSE_TOAST':
        return {
          ...state,
          show: false
        }
      case 'SET_TIMEOUT_ID':
        return {
          ...state,
          timeoutId: action.data
        }
      case 'CLEAR_TIMEOUT':
        clearTimeout(state.timeoutId)
        return {
          ...state,
          timeoutId: 0
        }
      default:
        return state
    }
  }
  
  export default toasterReducer
  